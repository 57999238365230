import React from 'react';

import { Chip, Skeleton } from '@mui/material';

import { Bookmark } from '@mui/icons-material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSatellite } from '@fortawesome/free-solid-svg-icons';
import useChipStyles from './useChipStyles';
import { Icon } from 'ReusableComponents/ProjectTypeIcon/ProjectTypeIcon';

const TypeChip = ({ className, type = 'map', skeleton, ...chipProps }) => {
  const parsedType =
    type === 'pointCloud'
      ? type
      : type === 'map' || type === 'raster'
      ? 'map'
      : type === 'shape' || type === 'vector'
      ? 'shape'
      : type?.toLowerCase();

  // const parsedColor = parsedType === 'bookmark' ? 'map' : parsedType;
  const labelText = parsedType === 'map' ? 'raster' : parsedType === 'shape' ? 'vector' : type;

  const { classes: styles, cx } = useChipStyles();

  return skeleton ? (
    <Skeleton className={cx(styles.root, className)} variant="rounded" animation="wave" width={92} height={32} />
  ) : (
    <Chip
      {...chipProps}
      color={parsedType}
      icon={
        parsedType === 'bookmark' ? (
          <Bookmark fontSize="small" />
        ) : parsedType === 'map' ? (
          <Icon type="raster" fontSize="small" />
        ) : parsedType === 'shape' ? (
          <Icon type="vector" fontSize="small" />
        ) : parsedType === 'folder' ? (
          <Icon type="folder" fontSize="small" />
        ) : parsedType === 'file' ? (
          <Icon type="file" fontSize="small" />
        ) : parsedType === 'pointCloud' ? (
          <Icon type="pointCloud" fontSize="small" />
        ) : parsedType === 'process' ? (
          <Icon type="process" fontSize="small" />
        ) : (
          <FontAwesomeIcon icon={faSatellite} />
        )
      }
      label={labelText}
      className={cx(styles.root, className)}
    />
  );
};

export default TypeChip;

import React, { useState, useCallback } from 'react';

import { IconMenuItem, NestedMenuItem } from 'mui-nested-menu';

import {
  ListItemIcon,
  ListItemText,
  Typography,
  Button,
  DialogContent,
  Radio,
  TextField,
  DialogActions,
  DialogContentText,
  RadioGroup,
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
} from '@mui/material';
import { KeyboardArrowRight, SatelliteAlt, Publish } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import DropZoneArea from '../../../ReusableComponents/DropZoneArea';
import UploadPreview from 'FilesManagement/UploadPage/UploadDropzone/UploadPreview';
import { validateFileFormat } from 'FilesManagement/UploadPage/UploadPage';
import { useNotificationSetter } from 'Navbar/Notifications';
import { useMainContext } from 'ReusableComponents';
import { useUploadLogic } from 'UploadDialog';
import { useMinimalAuth } from 'hooks';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import ApiManager from 'ApiManager';
import { Icon } from 'ReusableComponents/ProjectTypeIcon/ProjectTypeIcon';

const options = [
  ...(ApiManager.cloud === 'esa' ? [{ name: 'New process', type: 'process', icon: <Icon type="process" /> }] : []),
  { name: 'Import from Constellation', type: 'constellation', icon: <SatelliteAlt fontSize="small" /> },
  {
    name: 'Import from geodatabase',
    description:
      'zip your geodatabase and upload it. The server will create a layer for each layer in your geodatabase',
    type: 'geodatabase',
    icon: <Publish fontSize="small" />,
  },
  {
    name: 'Import from geopackage',
    description: 'Upload your geopackage here. The server will create a layer for each layer in your geopackage. ',
    type: 'geopackage',
    icon: <Publish fontSize="small" />,
  },
  {
    name: 'Import from shape',
    description: 'Zip your shape files and upload them. The server will create a layer for each layer in your shape',
    type: 'shape',
    icon: <Publish fontSize="small" />,
  },
  {
    name: 'Import from netCDF',
    description:
      'Upload your netCDF here. The server will create layers and timestamps based on the content of your file.',
    type: 'nc',
    icon: <Publish fontSize="small" />,
  },
];

//const options = [{ name: 'Import from Constellation', type: 'constellation', icon: <SatelliteAlt fontSize="small" /> }];

const useDropZoneStyles = makeStyles({ name: 'UploadDropZone' })((theme) => ({
  dropZone: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: theme.spacing(3),
  },
  text: {},
  uploadButton: {
    width: '180px',
    alignSelf: 'center',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  fileNumberContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    flexGrow: 1,
  },
  deleteAllButton: {},
  focusOverride: { '&&&': { backgroundColor: 'initial', '&:hover': { backgroundColor: theme.palette.action.hover } } },
}));

const EMPTY_EPSG = 'Fill in an epsg code';

const DialogCustomContent = ({ uploadFormats, onClose, description, type }) => {
  const { classes } = useDropZoneStyles();
  const setMenuOpen = useNotificationSetter();
  const [files, setFiles] = useState([]);
  const { onOpenSnackbar } = useMainContext();
  const { handleStartUpload } = useUploadLogic();
  const { currentPathId } = useCurrentFolder();
  const user = useMinimalAuth();

  const [epsg, setEpsg] = useState({ active: false, epsg: null });

  const handleFileChange = useCallback((files) => setFiles([...files]), [setFiles]);

  const removeFile = useCallback(
    (files) => {
      setFiles([]);
    },
    [setFiles]
  );

  const handleFilesUpload = () => {
    const onUploadSuccess = () => {
      onOpenSnackbar({ level: 'success', content: 'Import task has been received.' });
      setMenuOpen(true);
    };
    const onUploadError = () => {};
    const file = files[0];
    const name = file.name;
    const taskType =
      type === 'nc'
        ? 'netCDFImport'
        : type === 'geodatabase'
        ? 'geodatabaseImport'
        : type === 'shape'
        ? 'shapeImport'
        : 'geopackageImport';
    const body = {
      type: taskType,
      parameters: { pathId: currentPathId, name: name, epsg: epsg.active ? epsg.epsg : 4326 },
    };

    const captureId = null;
    const shouldSkip = false;
    const map = null;
    handleStartUpload(file, body, user, 'PATH_CREATION', captureId, onUploadSuccess, onUploadError, shouldSkip, map);

    onClose();
  };

  return (
    <>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>

        <List>
          <ListSubheader disableSticky>Get EPSG from:</ListSubheader>
          <ListItemButton
            onClick={() => {
              setEpsg({ active: false, epsg: null, error: undefined });
            }}
          >
            <ListItemIcon>
              <Radio color="primary" checked={!epsg.active} disableRipple />
            </ListItemIcon>
            <ListItemText primary="Auto detect from file" />
          </ListItemButton>

          <ListItemButton
            onClick={() => !epsg.active && setEpsg((old) => ({ ...old, active: !old?.active, error: EMPTY_EPSG }))}
            focusVisibleClassName={classes.focusOverride}
          >
            <ListItemIcon>
              <Radio color="primary" checked={epsg.active} disableRipple />
            </ListItemIcon>
            <TextField
              value={epsg.epsg || ''}
              onChange={(e) =>
                setEpsg({
                  active: true,
                  epsg: e.target.value,
                  error: e?.target?.value?.length === 0 ? EMPTY_EPSG : undefined,
                })
              }
              label="Manual EPSG Input"
              placeholder="4326"
              type="number"
              error={!!epsg.error}
              helperText={epsg?.error}
              onBlur={() =>
                epsg?.active &&
                (epsg?.epsg?.length === 0 || !epsg?.epsg) &&
                setEpsg((old) => ({ ...old, error: EMPTY_EPSG }))
              }
            />
          </ListItemButton>
        </List>

        <div className={classes.dropZone}>
          <DropZoneArea onChange={handleFileChange} />
        </div>
        <UploadPreview files={files} removeFile={removeFile} uploadFormats={uploadFormats} />
      </DialogContent>

      <DialogActions className={classes.titleContainer}>
        <div className={classes.fileNumberContainer}>
          <Typography>{files?.length > 0 ? `${files.length} file${files.length === 1 ? '' : 's'}` : ''}</Typography>
        </div>
        {files.length > 0 ? (
          <Button variant="text" color="primary" className={classes.deleteAllButton} onClick={() => setFiles([])}>
            Remove {files.length === 1 ? '' : 'all '}
            {files.length} file{files.length === 1 ? '' : 's'}
          </Button>
        ) : null}
        <Button
          className={classes.uploadButton}
          variant="contained"
          color="primary"
          disabled={
            files.filter((f) => !validateFileFormat(f.name.split('.').at(-1), uploadFormats).error).length === 0 ||
            !!epsg?.error
          }
          onClick={handleFilesUpload}
        >
          Upload
        </Button>
      </DialogActions>
    </>
  );
};

const NewMore = ({ open, close, setDialog }) => {
  const dialogs = {
    constellation: { type: 'constellation' },
    process: { type: 'process' },
    geodatabase: {
      type: 'geodatabase',
      title: 'Upload GeoDatabase',
      content: DialogCustomContent,
      uploadFormats: [
        {
          formats: ['zip'],
          type: 'success',
          uploadAs: 'gdb',
        },
      ],
      description:
        'zip your geodatabase and upload it. The server will create a layer for each layer in your geodatabase',
    },
    geopackage: {
      type: 'geopackage',
      uploadFormats: [
        {
          formats: ['gpkg', 'geopackage'],
          type: 'success',
          uploadAs: 'gpkg',
        },
      ],
      title: 'Upload GeoPackage',
      content: DialogCustomContent,
      description: 'Upload your geopackage here. The server will create a layer for each layer in your geopackage. ',
    },
    shape: {
      type: 'shape',
      title: 'Upload Shape',
      content: DialogCustomContent,
      uploadFormats: [
        {
          formats: ['zip'],
          type: 'success',
          uploadAs: 'shape',
        },
      ],
      description: 'Zip your shape files and upload them. The server will create a layer for each layer in your shape',
    },
    nc: {
      type: 'nc',
      title: 'Upload netCDF',
      content: DialogCustomContent,
      uploadFormats: [
        {
          formats: ['nc', 'netCDF'],
          type: 'success',
          uploadAs: 'nc',
        },
      ],
      description: 'Upload a netCDF file',
    },
  };

  return (
    <NestedMenuItem
      sx={{ color: 'action.active' }}
      rightIcon={<KeyboardArrowRight color="inherit" />}
      renderLabel={() => (
        <ListItemText
          primary="more"
          primaryTypographyProps={{ variant: 'button', color: 'common.gray2' }}
          sx={{ pl: 6.5 }}
        />
      )}
      parentMenuOpen={!!open}
    >
      {options.map((o, i) => (
        <IconMenuItem
          key={i}
          sx={{ py: 1, pl: 2, pr: 4 }}
          onClick={() => {
            close();
            setDialog(dialogs[o.type]);
          }}
          leftIcon={<ListItemIcon>{o.icon}</ListItemIcon>}
          renderLabel={() => (
            <ListItemText
              sx={{ height: '100%' }}
              primary={o.name}
              primaryTypographyProps={{ variant: 'button', color: 'common.gray2' }}
            />
          )}
        />
      ))}
    </NestedMenuItem>
  );
};

export default NewMore;

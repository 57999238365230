import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import bbox from '@turf/bbox';

import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import DrawRectangle from 'mapbox-gl-draw-rectangle-mode';

import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Drawer,
  Paper,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { Map, Search } from '@mui/icons-material';

import { useMainContext } from 'ReusableComponents';
import { createBbox, createFeatureCollection } from 'Drive/SearchInput/Helpers/MapWrapper';

import SearchPane from './Components/SearchPane';
import SearchResults from './Components/SearchResults';
import { parseStringPromise } from 'xml2js';
import ApiManager from 'ApiManager';
import { useMinimalAuth } from 'hooks';
import { useCurrentFolder } from 'Drive/CurrentFolderContext';
import { useNotificationSetter } from 'Navbar/Notifications';

// import bboxPolygon from '@turf/bbox-polygon';
// import { stringify } from 'wellknown';

const MAP_ZOOM = 24;
const DRAWER_WIDTH = 330;

const CLOUD_MIN = 0;
const CLOUD_MAX = 100;
// const CLOUD_DEFAULT = [CLOUD_MIN, 20];
const CLOUD_DEFAULT = [CLOUD_MIN, 20];
const CLOUD_OFF = [null, null];

const copernicus_url = 'https://catalogue.dataspace.copernicus.eu/resto/api/collections/';

export const CONSTELLATIONS = [
  { key: 'Sentinel1', value: 'SENTINEL-1', url: copernicus_url + 'Sentinel1/search.json', disabled: true },
  { key: 'Sentinel2', value: 'SENTINEL-2', url: copernicus_url + 'Sentinel2/search.json', disabled: false },
  { key: 'Sentinel3', value: 'SENTINEL-3', url: copernicus_url, disabled: true },
  { key: 'Sentinel5P', value: 'SENTINEL-5P', url: copernicus_url, disabled: true },
  { key: 'Sentinel6', value: 'SENTINEL-6', url: copernicus_url, disabled: true },
  { key: 'Sentinel1RTC', value: 'SENTINEL-1-RTC', url: copernicus_url, disabled: true },
  {
    key: 'GLOBAL-MOSAICS',
    value: 'GLOBAL-MOSAICS',
    url: copernicus_url + 'GLOBAL-MOSAICS/search.json',
    disabled: false,
  },
  { key: 'SMOS', value: 'SMOS', url: copernicus_url, disabled: true },
  { key: 'Envisat', value: 'ENVISAT', url: copernicus_url, disabled: true },
  { key: 'Landsat5', value: 'LANDSAT-5', url: copernicus_url, disabled: true },
  { key: 'Landsat7', value: 'LANDSAT-7', url: copernicus_url, disabled: true },
  { key: 'Landsat8', value: 'LANDSAT-8', url: copernicus_url, disabled: true },
  { key: 'COP-DEM', value: 'COP-DEM', url: copernicus_url, disabled: true },
  { key: 'TERRAAQUA', value: 'TERRAAQUA', url: copernicus_url, disabled: true },
  { key: 'S2GLC', value: 'S2GLC', url: copernicus_url, disabled: true },
];

const ACTIVE_CONSTELLATIONS = CONSTELLATIONS?.filter((i) => !i?.disabled);

const CREATE_STYLE = ({ source, color, fillOpacity = 0.4, lineWidth = 2 }) => [
  {
    id: source + '-fill',
    type: 'fill',
    paint: {
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        Math.round((Math.min(1, (fillOpacity / 3) * 2) + Number.EPSILON) * 100) / 100,
        fillOpacity,
      ],
      'fill-color': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#' + (Number(`0x1${color}`) ^ 0xffffff).toString(16).substr(1).toUpperCase(),
        '#' + color,
      ],
    },
    layout: { visibility: 'visible' },
    source: source,
    minzoom: 0,
    maxzoom: MAP_ZOOM,
  },
  {
    id: source + '-line',
    type: 'line',
    paint: { 'line-opacity': 1, 'line-color': '#' + color, 'line-width': lineWidth },
    layout: { visibility: 'visible' },
    source: source,
    minzoom: 0,
    maxzoom: MAP_ZOOM,
  },
];

const Constellation = ({ onClose }) => {
  const map = useRef(null);
  const mapContainer = useRef(null);
  const mapboxDraw = useRef(null);

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { onOpenSnackbar } = useMainContext();

  const { currentPathId } = useCurrentFolder();

  const user = useMinimalAuth();
  const setMenuOpen = useNotificationSetter();

  const [tab, setTab] = useState('search');

  const [constellation, setConstellation] = useState(ACTIVE_CONSTELLATIONS[0]);
  const [cloudCover, setCloudCover] = useState(CLOUD_DEFAULT);
  const [dates, setDates] = useState();
  const [bounds, setBounds] = useState(null);

  const [drawBounds, setDrawBounds] = useState(false);

  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);

  const [hoveredState, setHoveredState] = useState();

  const [searchOpen, setSearchOpen] = useState(true);

  const [resultOpen, setResultOpen] = useState();

  // const [products, setProducts] = useState();
  const [productDetails, setProductDetails] = useState({});

  const isOpen = useMemo(() => !isSmall || (isSmall && tab === 'search'), [isSmall, tab]);

  const hasCloudCover = useMemo(
    () =>
      constellation?.length > 0
        ? productDetails?.[constellation]?.OpenSearchDescription?.Url?.[0]?.['parameters:Parameter']?.find(
            (p) => p?.['$']?.name === 'cloudCover'
          )
        : true,
    [constellation, productDetails]
  );

  const hasNext = useMemo(
    () => !constellation?.custom && results?.properties?.links?.find((r) => r?.rel === 'next'),
    [results?.properties?.links]
  );

  const hasPrev = useMemo(
    () => results?.properties?.links?.find((r) => r?.rel === 'previous'),
    [results?.properties?.links]
  );

  const handleTabChange = useCallback((event, newValue) => setTab(newValue), []);

  const flyTo = useCallback(
    (geometry, type) => {
      if (type !== 'results') handleTabChange(undefined, 'map');

      if (geometry?.features?.length > 0 || geometry?.coordinates?.[0]?.length > 1) {
        map.current.fitBounds(new maplibregl.LngLatBounds(bbox(geometry)), { padding: 20 });
      } else {
        map.current.flyTo({ center: [0, 0], zoom: 0.1 });
      }
    },
    [handleTabChange]
  );

  const handleBoundsClick = useCallback(
    (e) =>
      setDrawBounds((oldDrawBounds) => {
        setBounds(null);

        if (oldDrawBounds === false) {
          if (!map?.current?.hasControl(mapboxDraw.current)) {
            map.current.addControl(mapboxDraw.current);
          }

          handleTabChange(e, 'map');
          onOpenSnackbar({ level: 'info', content: 'Draw bounds on map.' });

          map.current.getCanvas().style.cursor = 'crosshair';
        } else {
          mapboxDraw.current?.deleteAll();

          map.current.getCanvas().style.cursor = 'unset';
        }

        return !oldDrawBounds;
      }),
    [handleTabChange, onOpenSnackbar]
  );

  const handleSearch = useCallback(
    (url) => {
      setLoading(true);

      const { from, to } = { ...dates };
      const startDate = from?.toISOString();
      const completionDate = to?.toISOString();

      const [cf, ct] = [...cloudCover];
      let fetchUrl;
      if (constellation.custom) {
        fetchUrl = constellation.url;
        fetchUrl = fetchUrl.replace(`{xMin}`, bounds.xMin);
        fetchUrl = fetchUrl.replace(`{yMin}`, bounds.yMin);
        fetchUrl = fetchUrl.replace(`{xMax}`, bounds.xMax);
        fetchUrl = fetchUrl.replace(`{yMax}`, bounds.yMax);

        fetchUrl = fetchUrl.replace(`{startDate}`, from?.toISOString());
        fetchUrl = fetchUrl.replace(`{endDate}`, to?.toISOString());
      } else {
        const params = new URLSearchParams({
          ...(typeof cf === 'number' && typeof ct === 'number' && hasCloudCover
            ? { cloudCover: JSON.stringify(cloudCover) }
            : {}),
          ...(startDate ? { startDate } : {}),
          ...(completionDate ? { completionDate } : {}),
          ...(bounds ? { box: `${bounds?.xMin}, ${bounds?.yMin}, ${bounds?.xMax}, ${bounds?.yMax}` } : {}),
        });

        /* fetch(
          `https://datahub.creodias.eu/odata/v1/Products?$filter=((OData.CSC.Intersects(Footprint=geography'SRID=4326;${stringify(
            bboxPolygon([bounds?.xMin, bounds?.yMin, bounds?.xMax, bounds?.yMax, bounds?.xMin])
          )}')))`
        ); */
        fetchUrl = url ?? `${constellation.url}?${params.toString()}`;
      }

      console.log('fetchurl', fetchUrl, constellation);
      fetch(fetchUrl)
        .then((res) => {
          console.log('res', res);
          if (res.status !== 200) {
            onOpenSnackbar({ content: res.statusText, level: 'error' });
            throw Error(res.statusText);
          }
          return res?.json();
        })
        .then((res) => {
          setLoading(false);
          console.log('res', res);
          setResults({
            ...res,
            features: res?.features?.map((f) => ({ ...f, properties: { ...f?.properties, uuid: f?.id } })),
          });
        })
        .catch((err) => {
          console.log('in error');
          console.error(err);

          setLoading(false);
          setResults([]);
        });
    },
    [bounds, cloudCover, constellation, dates, hasCloudCover, onOpenSnackbar]
  );

  const handleNavButtons = useCallback(
    (type) => {
      handleSearch(type === 'next' ? hasNext?.href : hasPrev?.href);
    },
    [handleSearch, hasNext?.href, hasPrev?.href]
  );

  const handleImport = useCallback(
    (f) => {
      ApiManager.post(
        `/v3/path/setupTask`,
        {
          type: 'copernicusImport',
          parameters: {
            collection: f?.properties?.collection,
            title: f?.properties?.title,
            pathId: currentPathId,
            thumbnail: f?.properties?.thumbnail,
          },
        },
        user
      )
        .then((r) => {
          onClose();
          onOpenSnackbar({ level: 'success', content: 'Import task has been received.' });
          setMenuOpen(true);
        })
        .catch((err) => console.error(err));
    },
    [currentPathId, onClose, onOpenSnackbar, setMenuOpen, user]
  );

  //Map Init
  useEffect(() => {
    if (map.current) return; //stops map from intializing more than once

    map.current = new maplibregl.Map({
      renderWorldCopies: false,
      container: mapContainer.current,
      style: {
        version: 8,
        glyphs: 'https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf',
        sources: {
          osm: {
            type: 'raster',
            tiles: ['https://www.google.com/maps/vt?lyrs=y@189&x={x}&y={y}&z={z}'],
            zoom: 18,
            maxzoom: 18,
            tileSize: 256,
          },
          search: { type: 'geojson', data: { type: 'FeatureCollection', features: [] } },
          results: { type: 'geojson', data: { type: 'FeatureCollection', features: [] }, promoteId: 'uuid' },
        },
        layers: [
          {
            id: 'osm',
            type: 'raster',
            source: 'osm',
            minzoom: 0,
            maxzoom: MAP_ZOOM,
          },
          ...CREATE_STYLE({ source: 'search', color: 'ffff00' }),
          ...CREATE_STYLE({ source: 'results', color: '0000ff', fillOpacity: 0.2 }),
        ],
      },
      center: [0, 0],
      zoom: 0.1,
      maxZoom: MAP_ZOOM,
      maxPitch: 0,
      dragRotate: false,
    });

    map.current.addControl(new maplibregl.NavigationControl({ showCompass: false }));

    const modes = MapboxDraw.modes;
    modes.draw_rectangle = DrawRectangle;
    mapboxDraw.current = new MapboxDraw({
      modes: modes,
      displayControlsDefault: false,
      defaultMode: 'draw_rectangle',
      controls: { line_string: false, polygon: false, point: false, trash: false },
    });

    function onCreated(e) {
      if (map.current) {
        map.current.getCanvas().style.cursor = 'unset';

        setDrawBounds(false);
        setBounds(createBbox(bbox(e.features.at(-1))));
        setTab('search');

        mapboxDraw.current?.deleteAll();

        setTimeout(() => {
          if (map?.current?.hasControl(mapboxDraw.current)) {
            map?.current?.removeControl(mapboxDraw.current);
          }
        }, 100);
      }
    }

    function onMouseMove(e) {
      if (map.current && e.features.length > 0) {
        setHoveredState({ id: e.features.at(-1)?.id, type: 'map' });
      }
    }

    function onMouseLeave() {
      if (map.current) {
        map.current.getCanvas().style.cursor = 'unset';
        setHoveredState({});
      }
    }

    function onClick(e) {
      if (map.current) {
        setSearchOpen(false);
        setTab('search');
        setResultOpen((old) => (old === e?.features?.at(-1)?.id ? undefined : e?.features?.at(-1)?.id));
      }
    }

    map.current.on('click', 'results-fill', onClick);

    map.current.on('draw.create', onCreated);
    map.current.on('mousemove', 'results-fill', onMouseMove);
    map.current.on('mouseenter', 'results-fill', () => {
      map.current.getCanvas().style.cursor = 'pointer';
    });
    map.current.on('mouseleave', 'results-fill', onMouseLeave);
  }, []);

  useEffect(() => {
    map.current?.getSource('search')?.setData(createFeatureCollection(bounds));
  }, [bounds]);

  useEffect(() => {
    map.current?.getSource('results')?.setData(results);

    if (results && map.current) {
      console.log(results);
      flyTo(results, 'results');
    }
  }, [flyTo, results]);

  const prevHoverId = useRef();
  useEffect(() => {
    if (map.current && map.current.isStyleLoaded()) {
      if (prevHoverId.current) {
        map.current.setFeatureState({ source: 'results', id: prevHoverId.current }, { hover: false });
      }

      prevHoverId.current = hoveredState?.id;
      if (hoveredState?.id) {
        map.current.setFeatureState({ source: 'results', id: hoveredState?.id }, { hover: true });
      }
    }
  }, [hoveredState?.id]);

  useEffect(() => {
    if (constellation?.length > 0 && !productDetails[constellation]) {
      fetch(
        `https://catalogue.dataspace.copernicus.eu/resto/api/collections/${
          constellation?.length === 0 ? '' : constellation + '/'
        }describe.xml`
      )
        .then((res) => res.text())
        .then((text) => parseStringPromise(text))
        .then((parsed) => setProductDetails((old) => ({ ...old, [constellation]: parsed })))
        .catch((err) => console.error(err));
    }
  }, [constellation, productDetails]);

  useEffect(() => {
    map.current?.resize();
  }, [isSmall]);

  return (
    <Box sx={{ display: 'grid', gridTemplateRows: '1fr auto', height: '100%', maxHeight: '100%' }}>
      <Box sx={{ position: 'relative' }}>
        <Drawer
          PaperProps={{
            sx: {
              position: 'unset',
              display: 'grid',
              gridTemplateRows: 'min-content 1fr auto',
              overflow: 'hidden',
              gap: '1px',
            },
          }}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: !isSmall ? `calc(100% - ${DRAWER_WIDTH}px)` : 0,
            zIndex: 3,
            pointerEvents: isSmall && !isOpen ? 'none' : undefined,
          }}
          open={isOpen}
          variant={'persistent'}
          ModalProps={{ keepMounted: true }}
        >
          <SearchPane
            constellation={constellation}
            setConstellation={setConstellation}
            cloudCover={cloudCover}
            setCloudCover={setCloudCover}
            dates={dates}
            setDates={setDates}
            drawBounds={drawBounds}
            bounds={bounds}
            setBounds={setBounds}
            handleBoundsClick={handleBoundsClick}
            handleSearch={handleSearch}
            loading={loading}
            featureLength={results?.features?.length}
            searchOpen={searchOpen}
            setSearchOpen={setSearchOpen}
            hasCloudCover={hasCloudCover}
            resultOpen={resultOpen}
            setResultOpen={setResultOpen}
          />
          <SearchResults
            features={results?.features}
            loading={loading}
            hoveredState={hoveredState}
            setHoveredState={setHoveredState}
            resultOpen={resultOpen}
            setResultOpen={setResultOpen}
            flyTo={flyTo}
            handleImport={handleImport}
            setSearchOpen={setSearchOpen}
          />
          <Stack component={Paper} p={1} direction="row" justifyContent="space-evenly">
            <Button disabled={searchOpen || !hasPrev} onClick={() => handleNavButtons('prev')}>
              Previous
            </Button>
            <Button disabled={searchOpen || !hasNext} onClick={() => handleNavButtons('next')}>
              Next
            </Button>
          </Stack>
        </Drawer>
        <Box
          sx={{
            top: 0,
            left: !isSmall ? DRAWER_WIDTH : 0,
            bottom: 0,
            right: 0,
            overflow: 'hidden',
            '&&': { position: 'absolute' },
          }}
        >
          <Box ref={mapContainer} sx={{ height: '100%', width: '100%' }} />
        </Box>
      </Box>

      {isSmall && (
        <BottomNavigation value={tab} onChange={handleTabChange}>
          <BottomNavigationAction label="Search" value="search" icon={<Search />} />
          <BottomNavigationAction label="Map" value="map" icon={<Map />} />
        </BottomNavigation>
      )}
    </Box>
  );
};

export default Constellation;
export { CLOUD_MIN, CLOUD_MAX, CLOUD_DEFAULT, CLOUD_OFF, ACTIVE_CONSTELLATIONS };

import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';

const SelectedItemContext = createContext();

const SelectedItemProvider = ({ root, pathId, children }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(-1);
  const addSelectedItems = useCallback((newItems) => {
    setSelectedItems((items) => [...items, ...newItems]);
  }, []);

  const removeSelectedItems = useCallback((removeItems) => {
    setSelectedItems((items) => items.filter((item) => !removeItems.includes(item)));
  }, []);

  const resetSelectedItems = useCallback(() => setSelectedItems([]), []);

  // reset selection when root or pathId changes
  useEffect(() => {
    resetSelectedItems();
  }, [root, pathId, resetSelectedItems]);

  return (
    <SelectedItemContext.Provider
      value={{
        selectedItems,
        addSelectedItems,
        removeSelectedItems,
        setSelectedItems,
        resetSelectedItems,
        lastSelectedIndex,
        setLastSelectedIndex,
      }}
    >
      {children}
    </SelectedItemContext.Provider>
  );
};

const useSelectedItems = (props = {}) => {
  const { active = true } = props;

  const context = useContext(active && SelectedItemContext);

  if (active) {
    if (!context) {
      throw new Error('useSelectedItemContext must be used inside a SelectedItemContext.Provider');
    }

    return context;
  }
  return {};
};

export { useSelectedItems, SelectedItemProvider };

/* eslint-disable no-useless-escape */
export const createUrl = (url) => {
  url = url.replace(/#/g, '%23');
  url = url.replace(/\*/g, '%2a');
  url = url.replace(/\//g, '%2f');
  url = url.replace(/\+/g, '%2b');
  url = url.replace(/\(/g, '%28');
  url = url.replace(/\)/g, '%29');
  url = url.replace(/\^/g, '%5e');
  url = url.replace(/\-/g, '%2d');
  url = url.replace(/\&/g, '%26');

  return url;
};
/* eslint-enable no-useless-escape */

export const isValidHttpUrl = (string) => {
  try {
    new URL(string);
  } catch (_) {
    return false;
  }
  return true;
};

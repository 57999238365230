import { makeStyles } from 'tss-react/mui';

const useChipStyles = makeStyles({
  name: 'TypeChip',
})((theme) => ({
  root: {
    padding: theme.spacing(0, 1.5),
    gap: theme.spacing(1),
    borderRadius: theme.spacing(2),
    '& .MuiChip-icon': { margin: 0 },
    '& .MuiChip-label': { lineHeight: 1, padding: 0, textTransform: 'capitalize' },
    '& .MuiChip-deleteIcon': { margin: 0 },
  },
}));

export default useChipStyles;
